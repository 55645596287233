import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import funcAPI from 'modules/product-order.v2/product-order.api'

import {
  AsyncCallStatus
} from 'be-structures/typescript-generator/assembly'

import {
  IOrderClass,
  Order,
  ICustomerResource,
  ICustomerClass,
  Customer,
  IOrderResource,
  Company,
  ICompanyClass,
  ILocation, IPaxTypeInformation
} from 'modules/product-order.v2/data/product-order.types'
import {Store} from "vuex";
import {CustomerResource} from "be-structures/typescript-generator";
import {AxiosResponse} from "axios";

export const PRODUCT_ORDER_NAME = 'PRODUCT_ORDER_MODULE'

export const  PRODUCT_ORDER = (http: any, store: Store<any>) => {
const api = funcAPI(http)
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  @Module({ name: PRODUCT_ORDER_NAME, namespaced: true, store: store })
  class ProductOrderModule extends VuexModule {
    private _order: IOrderClass = new Order(null)
    private _agency: ICompanyClass = null
    private _countries: ILocation[] = []
    private _customerDOBrule: IPaxTypeInformation = null
    private _error: Error = null

    get customerDOBrule() {
      return this._customerDOBrule
    }
    get error() {
      return this._error
    }
    get order() {
      return this._order
    }

    get agency() {
      return this._agency
    }

    get countries() {
      return this._countries
    }



    @Mutation
    setCustomerDOBRule({rule}: {rule: IPaxTypeInformation}) {
      this._customerDOBrule = rule
    }
    @Mutation
    setAgency(agency: ICompanyClass) {
      this._agency = agency
    }

    @Mutation
    setOrder(order: IOrderClass) {
      this._order = order
    }

    @Mutation
    setOrderContactPerson({ contactPerson }: { contactPerson: ICustomerClass }) {
      this._order.contactPerson = contactPerson
    }

    @Mutation
    setOrderItems({ items }: { items: IOrderResource['items'] }) {
      this._order.items = items
    }

    @Mutation
    setCountriesList({ countries }: { countries: ILocation[] }) {
      this._countries = countries
    }



    @Action
    async getPaxType({ itemId, birthday }: {itemId: string, birthday: string}) {
      try {
        const getPaxType: AxiosResponse<IPaxTypeInformation> = await api.getPaxType({itemId, birthday})
        this.context.commit('setCustomerDOBRule', {rule: getPaxType})
      } catch (e) {
        this.context.commit('setError', {error:e})
      }
    }
    @Action
    async getAndSetDefaultWebsite() {
      const agency: ICompanyClass = (await api.getDefaultWebsite()).agency
      this.context.commit('setAgency', agency)

      return agency
    }

    @Action
    async beginOrder({ agency }: { agency: ICompanyClass }) {
      const orderResource = await api.beginOrder(agency.entityId)
      this.context.commit('setOrder', new Order(orderResource))
    }

    @Action
    async addProductToOrder(
      {
        orderId,
        productId,
        complectIds
      }: {
        orderId: string,
        productId: string,
        complectIds: string[]
      }
    ) {
      const items = await api.addProductToOrder({ orderId, productId, complectIds })
      this.context.commit('setOrderItems', { items })
    }

    @Action
    async addBaggageToCustomer(
      {
        orderId,
        itemId,
        customerId,
        additionalPaymentId
      }: {
        orderId: string,
        itemId: string,
        customerId: string
        additionalPaymentId: string
      }
    ) {
      return api.addBaggageToCustomer({ orderId, itemId, customerId, additionalPaymentId })
    }

    @Action
    async registrationNewCustomer({ orderId, customer }: { orderId: string, customer: ICustomerResource }): Promise<CustomerResource> {
      const customerData = await api.registrationNewCustomer({ orderId, customer })
      this.context.commit('setOrderContactPerson', { contactPerson: new Customer(customerData) })

      return customerData
    }

    @Action
    async setContactPerson({ orderId, customerId }: { orderId: string, customerId: string }) {
      return await api.setContactPerson({ orderId, customerId })
    }

    @Action
    async registationNewOwnerAndAddToOrder({ orderId, customer }: { orderId: string, customer: ICustomerResource }): Promise<CustomerResource> {
      const owner = await this.registrationNewCustomer({ orderId, customer })
      await this.setContactPerson({ orderId, customerId: owner.customerId })
      this.context.commit('setOrderContactPerson', { contactPerson: new Customer(owner) })
      return owner
    }

    @Action
    async addCustomerToOrderItem({ orderId, itemId, customerId }: { orderId: string, itemId: string, customerId: string }) {
      return api.addCustomerToOrderItem({ orderId, itemId, customerId })
    }

    @Action
    async registationNewCustomerAndAddToOrder({ orderId, itemId, customer }: { orderId: string, itemId: string, customer: ICustomerResource }):Promise<CustomerResource> {
      const customerData = await this.registrationNewCustomer({ orderId, customer })
      await this.addCustomerToOrderItem({ orderId, itemId, customerId: customerData.customerId })
      return customerData
    }

    @Action
    async getPreReservationStatus({ orderId }: { orderId: string }): Promise<AsyncCallStatus> {
      return new Promise(async (resolve, reject) => {
        const data = await api.startOrderPreReservation({ orderId })

        if (data.status === 'AWAIT') {
          await delay(800)
          resolve(this.getPreReservationStatus({ orderId }))
        } else {
          resolve(data.status)
        }
      })
    }

    @Action
    async startReservationStatus({ orderId }: { orderId: string }): Promise<AsyncCallStatus> {
      return api.startOrderReservation({ orderId })
    }

    @Action
    async getReservationStatus({ orderId }: { orderId: string }): Promise<AsyncCallStatus | "EXP"> {
      let interval;
      let poolExp =  false;
      let loopStatus = true;
      return new Promise(async (resolve, reject) => {
        interval = setInterval(() => {
          poolExp = true;
        }, 1000*2*60); // 1000 ms to sec 60 sec to min // total 2 min
        while (loopStatus) {
          const data = await api.getReservationStatus({ orderId })
          if (poolExp) {
            clearInterval(interval);
            loopStatus = false;
            resolve("EXP");
          }

          if (data.status === 'OK' || data.status === 'ERROR') {
            loopStatus = false;
            clearInterval(interval);
            resolve(data.status)
          }
          if (data.status === 'AWAIT') {
            await delay(800)
          } else {
            loopStatus = false;
            clearInterval(interval);
            resolve(data.status)
          }
        }
      })
    }

    @Action
    async getCountriesList() {
      const countries: ILocation[] = await api.getCountriesList()
      this.context.commit('setCountriesList', { countries })
    }
  }

  return ProductOrderModule
}
