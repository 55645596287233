import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import registerProductSearch from 'modules/product-search.v2/product-search.entry'
import registerProductSearchView from 'applications/common/components/prepare-search-data/vuex/entry'
import registerProductResultView from 'applications/common/components/search-results/vuex/entry'
import registerProductResult from 'modules/product-result.v2/product-result.entry'

import {
  ICountriesWithDigest
} from 'modules/product-search.v2/data/product-search.types'

import {
  IConvertToPackageResult
} from 'modules/product-result.v2/data/product-result.types'

import {
  IStartEnd,
  IQueryDestinations,
  TPaxes
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

import getHTTP from 'common/http/http'
import getStoreModules from 'applications/common/store/storeModules'

import { DealFeature, PriceCalendarPeriodResource, VacationType } from 'be-structures/typescript-generator'
@Component
export default class PrepareSearchDataCommon extends Vue {
  showNoResultsModal: boolean = false
  dontShowNoResultsModal: boolean = false
  showSetAllParamsModal: boolean = false
  isShowSpinner: boolean = false
  http = getHTTP(this.$store)

  created() {
    registerProductSearchView(this.http, this.$store)
    registerProductSearch(this.http, this.$store)

    this.initializePrepareSearchPage()
  }

  get storeModules() {
    return getStoreModules(this.http, this.$store)
  }

  get searchQuery() {
    return this.storeModules.PRODUCT_SEARCH.searchQuery
  }

  get destinations() {
    return this.storeModules.PRODUCT_SEARCH.destinations
  }

  get quantityOfDestinations(): number {
    return Object.keys(this.destinations).length
  }

  get quantityOfPassengers(): number {
    return this.searchQuery ? this.searchQuery?.getQuantityOfPassengers() : 0
  }

  get allVacationTypes() {
    return this.storeModules.PRODUCT_SEARCH_VIEW.allVacationTypes
  }

  get destinationPending() {
    return this.storeModules.PRODUCT_SEARCH.pendings.getAndSetCountries
  }

  get selectedDestination() {
    return this.destinations[this.storeModules.PRODUCT_RESULT_VIEW.selectedCountryCode] || null
  }

  setQueryDateInterval(interval: IStartEnd<string>) {
    this.searchQuery.dateInterval = interval
    this.storeModules.PRODUCT_SEARCH.setSearchQuery(this.searchQuery)
    this.updateDestinationsAfterChangingQuery()
  }

  setQueryDatePrecision(exactDates: boolean) {
    this.searchQuery.exactDates = exactDates // TODO make exactDates_temp after BE fix range dates
    this.storeModules.PRODUCT_SEARCH.setSearchQuery(this.searchQuery)

    if (exactDates) {
      this.storeModules.PRODUCT_SEARCH_VIEW.setSearchWithSpecificCountry(true)
      this.getAndSetDestinationCountries({})
      this.setQueryDateInterval({ start: null, end: null })
    } else {
      registerProductResultView(this.http, this.$store)

      getStoreModules(this.http, this.$store).PRODUCT_RESULT_VIEW.setCountryCode(null)
      this.setQueryDateInterval(this.storeModules.PRODUCT_SEARCH_VIEW.vacationTermBlank.dateInterval)
      this.storeModules.PRODUCT_SEARCH_VIEW.setSearchWithSpecificCountry(false)
    }
  }

  setQueryVacationTypes(vacationTypes: VacationType[]) {
    this.searchQuery.setVacationTypes(vacationTypes)
    this.updateDestinationsAfterChangingQuery()
  }

  setQueryPaxes(paxes: TPaxes) {
    this.searchQuery.paxes = paxes
    this.storeModules.PRODUCT_SEARCH.setSearchQuery(this.searchQuery)
    this.updateDestinationsAfterChangingQuery()
  }

  updateDestinationsAfterChangingQuery() {
    if (!this.storeModules.PRODUCT_SEARCH_VIEW.searchWithSpecificCountry) {
      this.getAndSetDestinationCountries({})
    }
  }

  getReturnDates(dateObject: PriceCalendarPeriodResource) {
    this.storeModules.PRODUCT_SEARCH.getAndSetDatesByCountry({
      cityCode: dateObject.cityCode,
      flightGroup: 'returns',
      // @ts-ignore
      departureDate: dateObject.departureDate
    })
  }

  canGoToSearch(goToResultPage: () => void) {
    if (!this.storeModules.PRODUCT_SEARCH.pendings.getAndSetCountries) {
      if (!this.searchQuery.dateIntervalExist()) {
        this.showSetAllParamsModal = true

      } else if (this.quantityOfDestinations > 0) {
        goToResultPage()

      } else {
        this.showNoResultsModal = !this.dontShowNoResultsModal

      }
    } else {
      this.isShowSpinner = true
    }
  }

  closeNoResultsModal(dontShow: boolean) {
    this.showNoResultsModal = false
    this.dontShowNoResultsModal = dontShow
  }

  closeSetAllParamsModal() {
    this.showSetAllParamsModal = false
  }

  initializePrepareSearchPage() {
    if (!this.searchQuery) {
      registerProductSearch(this.http, this.$store)
      this.storeModules.PRODUCT_SEARCH.initializeSearchQuery({ searchQuery: this.storeModules.PRODUCT_SEARCH_VIEW.vacationTermBlank })
    }

    if (!this.searchQuery.exactDates) {
      // PRODUCT_RESULT_VIEW_MODULE.resetStore()
    }
    this.getAndSetDestinationCountries({})
  }

  async setQueryDestination(destinations: IQueryDestinations) {
    const countryCode = Object.keys(destinations)[0]

    registerProductResultView(this.http, this.$store)

    this.storeModules.PRODUCT_SEARCH.setDestinations(destinations)
    getStoreModules(this.http, this.$store).PRODUCT_RESULT_VIEW.setCountryCode(countryCode)

    await this.storeModules.PRODUCT_SEARCH.getAndSetDatesByCountry({
      countryCode,
      flightGroup: 'departures'
    })
  }

  async getAndSetDestinationCountries({ filter, initializeFilter = true }: { filter?: IConvertToPackageResult, initializeFilter?: boolean }) {
    let data: ICountriesWithDigest = { countries: [], digest: null }
    
    if (this.storeModules.PRODUCT_SEARCH_VIEW.searchWithSpecificCountry) {
      const countries = await this.storeModules.PRODUCT_SEARCH.getAndSetDestinationCountriesForCalendar()
      data = { ...data, countries }
    } else {
      data = await this.storeModules.PRODUCT_SEARCH.getAndSetDestinationCountriesWithDigest({ filter })
    }

    if (initializeFilter) {
      registerProductResult(this.http, this.$store)
      getStoreModules(this.http, this.$store).PRODUCT_RESULT.initializeResultFilter({ digest: data.digest })
    }

    return Promise.resolve(data)
  }
}
